// File#: _1_pre-header
// Usage: codyhouse.co/license
(function() {
	function getCookie(name) {
		var value = "; " + document.cookie;
		var parts = value.split("; " + name + "=");
		if (parts.length === 2) return parts.pop().split(";").shift();
	}

	var preHeader = document.getElementsByClassName('js-pre-header');

	if (preHeader.length > 0) {
		var preHeaderClosed = getCookie("preHeaderClosed");

		if (preHeaderClosed) {
			for (var i = 0; i < preHeader.length; i++) {
				preHeader[i].classList.add('pre-header--hide');
			}
		} else {
			for (var i = 0; i < preHeader.length; i++) {
				(function(i){ addPreHeaderEvent(preHeader[i]);})(i);
			}
		}

		function addPreHeaderEvent(element) {
			var close = element.getElementsByClassName('js-pre-header__close-btn')[0];
			if (close) {
				close.addEventListener('click', function(event) {
					event.preventDefault();
					element.classList.add('pre-header--hide');

					var date = new Date();
					date.setTime(date.getTime() + (7*24*60*60*1000)); // 1 week
					var expires = "; expires=" + date.toUTCString();

					document.cookie = "preHeaderClosed=true" + expires + "; path=/";
				});
			}
		}
	}
}());
